import { UnitOfMeasure } from '@blancofoodcoach/content-scaling';

export default {
  email: 'Email',
  name: 'Naam',
  givenName: 'Voornaam',
  middleName: 'Tussenvoegsel',
  familyName: 'Achternaam',
  optional: '(optioneel)',
  password: 'Wachtwoord',
  female: 'Vrouw',
  male: 'Man',
  dateFormat: 'dd-mm-jjjj',
  filters: {
    doneIn5Mins: 'Klaar in 5 min',
    doneIn10Mins: 'Klaar in 10 min',
    doneIn20Mins: 'Klaar in 20 min',
    exact: 'Perfect passend',
    favorites: 'Favorieten',
    oat: 'Havermout',
    cottageCheese: 'Kwark',
    bread: 'Brood',
    easy: 'Eenvoudig',
    vegetarian: 'Vegetarisch',
    vegetableRich: 'Groente toppers',
    classics: 'Familiegerechten',
    bakingTogether: 'Samen bakken',
    vegan: 'Vegan',
    lowCarb: 'Koolhydraatarm',
    pasta: 'Pasta',
    rice: 'Rijst',
    lowCalories: 'Caloriearm',
    schijfVanVijf: 'Schijf van Vijf',
    season: 'Seizoen',
    team: 'Team Recepten',
    potato: 'Aardappel',
    italian: 'Italiaans',
    dutch: 'Hollands',
    myRecipes: 'Mijn recepten',
    mediterranean: 'Mediterraans',
    mexican: 'Mexicaans',
    oriental: 'Oosters',
    otherKitchen: 'Overig',
    all: 'Alles',
    other: 'Overig',
  },
  weightGoal: {
    maintain: 'Behouden',
    lose: 'Afvallen',
    gain: 'Aankomen',
  },
  gender: 'Geslacht',
  language: 'Taal',
  unitsOfMeasure: {
    [UnitOfMeasure.Grams]: 'g',
    [UnitOfMeasure.Milliliters]: 'ml',
    [UnitOfMeasure.Pieces]: 'st',
    [UnitOfMeasure.Pinches]: 'snuf',
    [UnitOfMeasure.Servings]: 'prt',
    [UnitOfMeasure.Slices]: 'snee',
    [UnitOfMeasure.Tablespoons]: 'el',
    [UnitOfMeasure.Teaspoons]: 'tl',
    [UnitOfMeasure.Twigs]: 'tak',
  },
  unitsOfMeasureFull: {
    [UnitOfMeasure.Grams]: 'gram',
    [UnitOfMeasure.Milliliters]: 'milliliter',
    [UnitOfMeasure.Pieces]: 'stuks',
    [UnitOfMeasure.Pinches]: 'snufjes',
    [UnitOfMeasure.Servings]: 'porties',
    [UnitOfMeasure.Slices]: 'sneetjes',
    [UnitOfMeasure.Tablespoons]: 'eetlepels',
    [UnitOfMeasure.Teaspoons]: 'theelepels',
    [UnitOfMeasure.Twigs]: 'takjes',
  },
  trainingImportance: {
    true: 'Primair',
    false: 'Secundair',
  },
  trainingImportanceFull: {
    true: 'Primaire training',
    false: 'Secundaire training',
  },
  losingWeight: 'Afvallen',
  gainingWeight: 'Aankomen',
  lockMeal: 'Moment vergrendelen',
  unlockMeal: 'Moment ontgrendelen',
  deleteAllContents: 'Verwijder alle inhoud',
};
