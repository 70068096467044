export const TRAINING_TYPES = {
  STRENGTH_TRAINING: 'Strength training',
  CARDIO_STRENGTH_TRAINING: 'Cardio + strength training',
  CARDIO_GROUP_TRAINING: 'Cardio/groep session',
  CIRCUIT_CROSSFIT_TRAINING: 'CrossFit',
};

export const trainingTypesTranslations = () => ({
  carbLoading: 'Carb loading',
  cardioGroupLessonEasy: 'Cardio/group session easy',
  cardioGroupLessonIntensive: 'Cardio/group session intensive',
  cardioGroupLessonModerate: 'Cardio/group session average',
  cardioStrengthEasy: 'Cardio + power training easy',
  cardioStrengthIntensive: 'Cardio + power training intensive',
  cardioStrengthModerate: 'Cardio + power training average',
  circuitCrossfitIntensive: 'CrossFit intensive',
  circuitCrossfitModerate: 'CrossFit average',
  cycloCross: 'Female CX TVL',
  endurance: 'Endurance training',
  enduranceLowCarbs: 'Endurance training (low carb)',
  gameDayEvening: 'Game day evening',
  gameDayMinusOneAm: 'Game day -1AM',
  gameDayMinusOnePm: 'Game day -1PM',
  gameDayMinusThreeAm: 'Game day -3AM',
  gameDayMinusThreePm: 'Game day -3PM',
  gameDayMinusTwoAm: 'Game day -2AM',
  gameDayMinusTwoPm: 'Game day -2PM',
  hardRace: 'Hard race',
  hardRaceLateDiner: 'Hard race (late diner)',
  hardRaceLateDinner: 'Heavy race (late dinner)',
  hardRaceLateStart: 'Hard race (late start)',
  highIntensity: 'High intensity',
  intense: 'VO2-max',
  intensiveTraining: 'Intensive training',
  interval: 'Interval training',
  keeperTraining: 'Goalkeeper training',
  matchDayKeeper: 'Match (goalkeeper)',
  matchDayKeeperAfternoon: 'Match (goalkeeper) 13:00-17:00',
  matchDayKeeperEvening: 'Match (goalkeeper) 17:00+',
  matchDayKeeperMinusOne: 'Match (goalkeeper) -1',
  matchDayPlayer: 'Match (field player)',
  matchDayPlayerAfternoon: 'Match (field-player) 13:00-17:00',
  matchDayPlayerEvening: 'Match (field-player) 17:00+',
  matchDayPlayerMinusOne: 'Match (field-player) -1',
  normalRace: 'Normal race',
  normalRaceLateDiner: 'Normal race (late diner)',
  normalRaceLateDinner: 'Normal race (late dinner)',
  normalRaceLateStart: 'Normal race (late start)',
  normalTraining: 'Normal training',
  normalTrainingKeeperAfternoon: 'Normal training (goalkeeper) 13:00-17:00',
  normalTrainingKeeperMorning: 'Normal training (goalkeeper)',
  normalTrainingPlayerAfternoon: 'Normal training (field-player) 13:00-17:00',
  normalTrainingPlayerMorning: 'Normal training (field-player)',
  raceDayMinusOne: 'Race day -1',
  recover: 'Recovery training',
  recovery: 'Active recovery',
  recoveryCycling: 'Spinning the legs',
  strength: TRAINING_TYPES.STRENGTH_TRAINING,
  strengthLittleResistanceLittleRest: 'Power training low resistance 0-120 sec rest',
  strengthLittleResistanceLotsRest: 'Power training low resistance > 120 sec rest',
  strengthLotsResistanceLittleRest: 'Power training high resistance 0-120 sec rest',
  strengthLotsResistanceLotsRest: 'Power training high resistance > 120 sec rest',
  technique: 'Technique',
  tempo: 'Tempo training',
  timeTrial: 'Time trial',
  timeTrialEarlyStart: 'Time trial (early start)',
  timeTrialLateStart: 'Time trial (late start)',
});
