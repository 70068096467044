import { trainingTypesTranslations, TRAINING_TYPES } from './trainingTypes.nl';

const FOOD_DURING_ACTIVITY = 'Eten tijdens je activiteit';

const trainingMoments = () => ({
  morning: 'Ochtend',
  afternoon: 'Middag',
  evening: 'Avond',
  beforeBreakfast: 'Ontbijt',
  beforeMorningSnack: 'Ochtendsnack',
  beforeLunch: 'Lunch',
  beforeAfternoonSnack: 'Middagsnack',
  beforeDinner: 'Diner',
  beforeEveningSnack: 'Avondsnack',
  beforeRecoverySnack: 'Shake',
  beforeHalfTime: 'Rust',
  beforeShake: 'Shake',
});

export default {
  activityLevel: 'Activiteitenniveau',
  activityLevelOnboarding: 'Hoe actief ben je buiten je sport en werk om?',
  activityLevels: {
    light: 'Gemiddeld actief',
    moderate: 'Erg actief',
    heavy: 'Extreem actief',
  },
  chooseRecipeMoments: 'Waar wil je dit recept nog meer tonen?',
  hideSyncedActivities:
    'Verberg {n} gesynchroniseerde activiteiten | Verberg {n} gesynchroniseerde activiteit | Verberg {n} gesynchroniseerde activiteiten',
  showSyncedActivities:
    'Toon {n} gesynchroniseerde activiteiten | Toon {n} gesynchroniseerde activiteit | Toon {n} gesynchroniseerde activiteiten',
  chooseFrom: 'Kies een',
  chooseRecipeName: 'Kies een naam voor je {n}',
  choseYourSport: 'Kies je teamsport',
  date: 'Datum',
  dateOfBirth: 'Geboortedatum',
  doesNotMatchMealPlan: 'Voldoet niet aan voedingsplan',
  ftpValue: 'FTP',
  highlightMoments: {
    preWorkoutMeal: 'Pre-workout maaltijd',
    preWorkoutSnack: 'Pre-workout snack',
    recoveryMeal: 'Herstelmaaltijd',
    recoverySnack: 'Shake',
  },
  intake: 'inname',
  hockey: {
    matchDayKeeper: 'Wedstrijd (keeper)',
    matchDayPlayer: 'Wedstrijd (veldspeler)',
    normalTraining: 'Normale training',
    intensiveTraining: 'Intensieve training',
    keeperTraining: 'Keeperstraining',
    gameDayEvening: 'Game day avond',
  },
  fitness: {
    strength: TRAINING_TYPES.STRENGTH_TRAINING,
    cardioGroup: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
    cardioStrength: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
    circuitCrossfit: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
    cardioStrengthEasy: 'Rustig',
    cardioStrengthModerate: 'Gemiddeld',
    cardioStrengthIntensive: 'Intensief',
    strengthLotsResistance: 'Weinig weerstand',
    strengthLittleResistance: 'Veel weerstand',
    littleRest: '0-120 sec',
    lotsRest: '> 120 sec',
    cardioGroupLessonEasy: 'Rustig',
    cardioGroupLessonModerate: 'Gemiddeld',
    cardioGroupLessonIntensive: 'Intensief',
    circuitCrossfitModerate: 'Gemiddeld',
    circuitCrossfitIntensive: 'Intensief',
  },
  hockeyDuration: 'Hoelang ga je trainen?',
  hockeyMoment: 'Ik ga trainen vóór mijn',
  hours: 'Uren',
  intensity: 'Je trainingsintensiteit',
  carbsPerHour: 'Koolhydraten per uur',
  intensityType: 'Intensiteit',
  restType: 'Rust tussen de sets',
  length: 'Lengte',
  mealDifficulties: {
    simple: 'eenvoudig',
    medium: 'gevorderd',
    hard: 'moeilijk',
  },
  moments: {
    afternoonSnack: 'Middagsnack',
    beforeAfternoonSnack: FOOD_DURING_ACTIVITY,
    beforeBreakfast: FOOD_DURING_ACTIVITY,
    beforeDinner: FOOD_DURING_ACTIVITY,
    beforeEveningSnack: FOOD_DURING_ACTIVITY,
    beforeHalfTime: FOOD_DURING_ACTIVITY,
    beforeLunch: FOOD_DURING_ACTIVITY,
    beforeMorningSnack: FOOD_DURING_ACTIVITY,
    beforeRecoverySnack: FOOD_DURING_ACTIVITY,
    beforeShake: FOOD_DURING_ACTIVITY,
    breakfast: 'Ontbijt',
    cherryJuice: 'Cherry juice',
    dinner: 'Diner',
    eveningSnack: 'Avondsnack',
    gel: 'Gel',
    halfTime: 'Rust',
    lunch: 'Lunch',
    morningSnack: 'Ochtendsnack',
    postGameMeal: 'Post-game meal',
    preGame: 'Pre-game',
    preGameMeal: 'Pre-game meal',
    preRaceMeal: 'Pre-race meal',
    preRaceSnack: 'Pre-race snack',
    preWarmingUp: 'Pre-warming-up',
    recon: 'Recon',
    recoveryMeal1: 'Herstelmaaltijd 1',
    recoveryMeal2: 'Herstelmaaltijd 2',
    recoveryMeal: 'Herstelmaaltijd',
    recoverySnack: 'Shake',
    shake: 'Shake',
    snack: 'Snack',
  },
  minutes: 'Minuten',
  minimumIntake: 'Minimale energiebehoefte:',
  notAvailable: 'Nog niet beschikbaar',
  nutrients: {
    calories: 'Energie',
    carbs: 'Koolhydraten',
    fat: 'Vetten',
    fiber: 'Vezels',
    protein: 'Eiwitten',
  },
  running: {
    distance: 'Afstand',
    tempo: 'Tempo (min/km)',
  },
  swimming: {
    tempo: 'Tempo (min/100m)',
  },
  recipeTypes: {
    breakfast: 'Ontbijt',
    lunch: 'Lunch',
    dinner: 'Diner',
    snack: 'Snack',
  },
  searchRecipes: 'Wat wil je eten?',
  sleepingHours: 'Slaapuren',
  soccer: {
    matchDayKeeper: 'Wedstrijd (keeper)',
    matchDayPlayer: 'Wedstrijd (veldspeler)',
    normalTraining: 'Normale training',
    intensiveTraining: 'Intensieve training',
    keeperTraining: 'Keeperstraining',
  },
  soccerDuration: 'Hoelang ga je trainen?',
  sports: {
    cycling: 'Wielrennen',
    fitness: 'Fitness',
    running: 'Hardlopen',
    teamsport: 'Teamsport',
    hockey: 'Hockey',
    soccer: 'Voetbal',
    swimming: 'Zwemmen',
    swimmingOpenWater: 'Zwemmen (open water)',
  },
  today: 'Vandaag',
  trainingDuration: 'Tijdsduur',
  trainingEnergy: 'Training energie',
  activityTrainingDuration: 'Geplande tijdsduur',
  activityActualDuration: 'Actuele tijdsduur',
  activityTrainingIntensity: 'Geplande intensiteit',
  activityActualIntensity: 'Actuele intensiteit',
  activityTrainingCarbsPerHour: 'Geplande koolhydraten per uur',
  activityActualCarbsPerHour: 'Actuele koolhydraten per uur',
  actualDuration: 'Hoelang heb je getraind?',
  trainingImportance: 'Trainingsbelang',
  trainingMoment: 'Ik ga sporten vóór mijn',
  trainingMoments: {
    ...trainingMoments(),
  },
  trainingMomentsPro: {
    ...trainingMoments(),
  },
  trainingTypes: {
    ...trainingTypesTranslations(),
  },
  trainingTypesPro: {
    ...trainingTypesTranslations(true),
  },
  trainingType: 'Type training',
  trainingMainType: 'Type training',
  intensityTypes: {
    strengthLotsResistanceLittleRest: 'Weinig weerstand',
    strengthLotsResistanceLotsRest: 'Weinig weerstand',
    strengthLittleResistanceLittleRest: 'Veel weerstand',
    strengthLittleResistanceLotsRest: 'Veel weerstand',
    cardioStrengthEasy: 'Rustig',
    cardioStrengthModerate: 'Gemiddeld',
    cardioStrengthIntensive: 'Intensief',
    cardioGroupLessonEasy: 'Rustig',
    cardioGroupLessonModerate: 'Gemiddeld',
    cardioGroupLessonIntensive: 'Intensief',
    circuitCrossfitModerate: 'Gemiddeld',
    circuitCrossfitIntensive: 'Intensief',
  },
  weight: 'Gewicht',
  workingHours: 'Werkuren',
  workType: 'Type werk',
  whichTeamSport: 'Welke teamsport doe je?',
  workTypes: {
    unemployed: 'Geen werk',
    sedentary: 'Zittend werk',
    light: 'Licht actief werk',
    moderate: 'Gemiddeld actief werk',
    heavy: 'Zwaar actief werk',
  },
  yourWeight: 'Je gewicht',
};
