export default {
  givenName: 'Voornaam',
  middleName: 'Tussenvoegsel',
  familyName: 'Achternaam',
  emails: "E-mails van de Athlete's FoodCoach",
  performanceGoal: 'Prestatiedoel',
  performanceGoalPlaceholder: 'Bijv. je event',
  date: 'Datum',
  preferredLanguage: 'Selecteer je voorkeurstaal',
  energyCalculation: {
    hb: {
      title: 'Harris & Benedict',
      description: 'Standaard gebruikt voor amateur atleten',
    },
    th: {
      title: 'Ten Haaf',
      description:
        'Gebruikt voor pro en ultra fanatieke atleten met relatief hogere vetvrije massa',
    },
  },
  nutritionView: {
    coach: {
      title: 'Coach tailored',
      description: 'De weergave van uw coach afgestemd op uw speciale behoeften',
    },
    core: {
      title: 'Core performance',
      description: 'Toon alleen eiwitten en koolhydraten voor focus op core performance',
    },
    advanced: {
      title: 'Advanced performance',
      description:
        'Toon calorieën, koolhydraten, eiwitten en vetten voor volledige voedingstracking en geavanceerde voedingsstrategieën, zoals afvallen',
    },
  },
  weightGoal: {
    custom: 'Coach instelling',
    weeklyTarget: 'Wekelijks richtpunt',
    dailyCalorieDeficit: 'Dagelijks calorie tekort',
    dailyCalorieSurplus: 'Dagelijks calorie overschot',
    maintain: {
      title: 'Gewicht behouden',
      description: 'Behoud je huidige gewicht om je gezondheid en prestaties te ondersteunen.',
    },
    lose: {
      title: 'Slim afvallen',
      description: 'Verlies gewicht geleidelijk zonder spiermassa en prestatie kwijt te raken.',
      targets: {
        halveKg: '-0.5 kg',
        quarterKg: '-0.25 kg',
      },
    },
    gain: {
      title: 'Slim aankomen',
      description:
        'Kom geleidelijk gewicht aan, ondersteun spieropbouw en minimaliseer toename vetmassa.',
      targets: {
        halveKg: '+0.5 kg',
        quarterKg: '+0.25 kg',
      },
    },
  },
  productDatabaseOptions: {
    default: 'Standaard FoodCoach',
    off: 'Wereldwijd Community Gedreven Database',
    nl: 'Nederland',
    be: 'België (Nederlands)',
    gb: 'Verenigd Koninkrijk',
    us: 'Verenigde Staten',
  },
  trainingPeaksManualSync: 'TrainingPeaks (handmatig)',
  whichGender:
    '<strong>Welk geslacht</strong> moeten we gebruiken om je energiebehoefte te berekenen?',
};
