<script>
  import { JumButton, JumIcon } from '@blancofoodcoach/kompas';
  import { computed, defineComponent, ref, watch } from 'vue';
  import FcTipBox from '@/components/library/tip-box/tip-box.vue';

  export default defineComponent({
    name: 'FcTipMenu',
    components: {
      FcTipBox,
      JumIcon,
      JumButton,
    },
    props: {
      buttons: {
        type: Array,
        required: true,
      },
      x: {
        type: Number,
        default: 0,
      },
      y: {
        type: Number,
        default: 0,
      },
      element: {
        type: HTMLElement,
        default: null,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const isTop = ref(false);
      const tipMenu = ref(null);
      const tipMenuHeight = computed(() => tipMenu.value?.$el.getBoundingClientRect().height ?? 0);
      const onClick = action => {
        emit('close');
        action();
      };

      watch(tipMenuHeight, () => {
        if (props.element) {
          const { top, bottom } = props.element.getBoundingClientRect();
          const spaceBetweenItemAndTipMenu = 16;
          const { innerHeight } = window;
          const { y } = props;

          if (y + tipMenuHeight.value > innerHeight) {
            tipMenu.value.$el.style.top = `${top - (tipMenuHeight.value + spaceBetweenItemAndTipMenu)}px`;
            isTop.value = true;
          } else {
            tipMenu.value.$el.style.top = `${bottom}px`;
          }
        }
      });

      return {
        onClick,
        tipMenu,
        isTop,
      };
    },
  });
</script>

<template>
  <fc-tip-box
    ref="tipMenu"
    class="tip-menu"
    :padded="false"
    :full-width="fullWidth"
    :style="{ top: `${y}px` }"
    :class="{ 'is-top': isTop }"
  >
    <template v-for="option in buttons">
      <jum-button
        v-if="option.condition !== undefined ? option.condition : true"
        :key="option.label"
        tertiary
        @click="() => onClick(option.action)"
      >
        <jum-icon v-if="option.icon" :icon="option.icon" size="20" left />
        <span>{{ option.label }}</span>
      </jum-button>
    </template>
  </fc-tip-box>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .is-top {
    :deep(#tip-box-tip) {
      &::before {
        background-color: #f94d3f;
        bottom: -4px;
        top: inherit;
      }

      &::after {
        border-top: 4px solid #fff;
        height: 8px;
      }
    }
  }

  button.jum-button.tertiary {
    align-items: center !important;
    display: flex;
    gap: $spacing-xs;
    padding: $spacing-m;
    white-space: nowrap;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:only-child {
      border-radius: 8px;
    }

    + .jum-button {
      border-top: 1px solid $color-gray-lighten-60;
    }
  }
</style>
