<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FcTipBox',
    props: {
      padded: {
        type: Boolean,
        default: true,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<template>
  <transition name="open">
    <div id="tip-box-position-container" class="tip-box">
      <div id="tip-box-inner-container" :class="{ 'full-width': fullWidth }">
        <div id="tip-box-tip" :class="{ padded }">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  #tip-box-position-container {
    background: $color-white;
    border: 1px solid $color-gray-lighten-80;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    margin-top: $spacing-s;
    position: absolute;
    right: 1rem;
    z-index: 10;

    &.full-width {
      width: 100%;
    }
  }

  #tip-box-tip {
    position: relative;
    right: 0;

    :deep(.jum-button) {
      align-items: start;
      width: 100%;
    }

    &.padded {
      padding: $spacing-m;
    }

    &::before {
      background: $color-white;
      border: 1px solid $color-gray-lighten-80;
      border-bottom: 0;
      border-right: 0;
      content: '';
      height: 7.5px;
      position: absolute;
      right: $spacing-m;
      top: -5px;
      transform: rotate(45deg);
      width: 7.5px;
    }

    &::after {
      background-color: #f94d3f;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      bottom: -1px;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      transition: opacity 0.2s ease-in-out;
      width: 100%;
    }
  }

  .open-enter,
  .open-leave-to {
    opacity: 0;
    transition: all ease 400ms;
  }

  .open-enter-active,
  .open-leave-active {
    transition: all ease 400ms;
  }

  .open-enter-to,
  .open-leave {
    opacity: 1;
  }
</style>
