export default {
  buttons: {
    accountExists: 'I already have an account',
    createAccount: 'Don’t have an account yet? <u>Create one here</u>',
    nextOnboardingCard: 'Next',
    skipOnboarding: 'Skip intro',
    password: {
      show: 'Show',
      hide: 'Hide',
    },
    cancel: 'Cancel',
    select: 'Select',
  },
  errors: {
    notSupported: 'This feature is not supported on your device',
  },
  serviceUnavailable: {
    heading: 'Maintenance',
    title: 'We are currently updating the app',
    description: 'The app is temporarily unavailable. Please try again later.',
  },
  networkErrors: {
    trainingExistsOnDate: 'You already have a training on this date',
  },
  inputErrors: {
    invalid: {
      rules:
        'Your password <strong>must</strong> be at least 8 characters long, contain one uppercase character, one lowercase character, one number and one special character (i.e.: !@#$%^&*)',
      date: 'Please submit a valid date',
      default: 'Your submitted value is incorrect',
      greaterThan: 'Submit a number which is greater than {greaterThanValue}',
      greaterThanOrEquals:
        'Submit a number which is greater than or equals {greaterThanOrEqualsValue}',
      smallerThan: 'Submit a number which is smaller than {smallerThanValue}',
      smallerThanOrEquals: 'Submit a number which is smaller than {smallerThanOrEqualsValue}',
      maxLength: 'Use up to {maxLengthValue} characters',
      maxPrecision: 'Use up to {maxPrecision} numbers after the decimal point',
      minLength: 'Use at least {minLengthValue} characters',
      number: 'Submit a number',
      decimal: 'Use 1 number after the decimal point',
      oneLowercase: 'Use at least one lowercase character',
      oneUppercase: 'Use at least one uppercase character',
      oneNumber: 'Use at least one number',
      specialCharacters: 'Use at least one special character i.e. !@#$%^&*',
      userMealParticipants: 'Select at least 1 person',
      username: 'You have entered an invalid e-mail address',
      ftp: 'Enter a minimum of 80 and a maximum of 500 watts',
      dateOfBirth: "You must be at least 16 years old to use The Athlete's FoodCoach",
      activityHours: 'Enter a minimum of 1 and a maximum of 99 hours',
      height: 'Enter a minimum of 130 and a maximum of 220 centimeters',
      weight: 'Enter a minimum of 40 and a maximum of 140 kg',
      workDayHours: 'Enter a maximum of 16 hours',
      sleepingHours: 'Enter a minimum of 1 and a maximum of 16 hours',
      tempo:
        'Enter a pace between 02:30 and 15:00. Make sure you use four numbers to enter the correct pace. ',
      tempo100m:
        'Enter a pace between 01:00 and 03:59. Make sure you use four numbers to enter the correct pace. ',
      wattage: 'Enter a minimum of 40% and a maximum of 100% of your FTP',
      carbsPerHour: 'Enter a minimum of 0 and a maximum of 120 grams',
      actualWattage: 'Enter a maximum of 800 watts',
      macroTotal: 'The total of your macronutrients cannot exceed 100',
    },
    required: {
      default: 'This field is required',
      password: 'You have not entered a password yet',
      username: 'You have not entered an email address yet',
      givenName: 'You have not entered your first name yet',
      gender: 'Please indicate whether you are male or female',
      dateOfBirth: 'Enter your date of birth',
      termsAndConditions: 'you need to agree to continue',
      sportsTermsAndConditions: 'Your permission is required to continue.',
      sportType: 'Select your sport',
      activityLevelWorkDay: 'Select the activity level of your working conditions',
      activityLevel: 'Enter your activity level outside of sports and work',
      duration: 'Select the length of your training',
      wattage: 'Enter a minimum of 40% and a maximum of 100% of your FTP',
      trainingMoment: 'Choose the meal moment after your training',
      trainingType: 'Choose the type of your activity',
      eventName: 'Enter the name of your performance goal',
      eventDate: 'Enter the date of your performance goal',
    },
  },
  onboarding: {
    slide1: {
      title: 'Are you a <strong>sports fanatic?</strong>',
      description:
        'Choose the sports module and get a personal nutrition plan to perform better 💪',
    },
  },
  idfa: {
    title: 'Can we track your activity?',
    text: `This way we can also get to know you better through other apps and websites in order to serve you even better.
    We would like to ask your permission for this once. Select “Allow” on the next screen for the most personalized experience.`,
    confirmText: 'Give your preference',
  },
  applications: {
    title: 'Connect your apps & devices',
    description:
      'FoodCoach will automatically import cycling or running training data. For cyclists, real power meter data is required for accurate import.',
    learnMore: 'Learn more.',
    close: 'You may now close this browser.',
  },
  premium: {
    pageTitle: 'FoodCoach Premium',
    title: 'FoodCoach Premium',
    benefits: [
      'Personal adaptive meal plan',
      'Sync Strava, Garmin, TrainingPeaks and more ',
      'Exclusive FoodCoach AI support',
      'Unlock medals, challenges & focus plans',
      'Explore  1500+ Pro Recipes',
      'Introductory subscription: {yearPrice}/yr or {monthPrice}/mo, cancel free within 7 days of subscribing',
      'Year plan: 14-day free trial',
    ],
    postBenefitsLabel: '... and much more!',
    earlyBirdTag: 'Special discount',
    chooseYourPaymentTitle: 'Choose your payment plan',
    discount: 'Save {discount}%',
    $rc_annual: 'Yearly',
    $rc_annual_description: 'Single payment for full year directly due after free trial',
    $rc_monthly: 'Monthly',
    $rc_monthly_description: 'Monthly payment directly due after free trial',
    subscribe: 'Subscribe',
    lookAround: 'I want to look around first',
    useApp: 'Log in via the iOS or Android app to start a subscription.',
    success: 'Your subscription has been successfully completed.',
    paymentFailed: 'Your payment has failed. Please try again later or contact support.',
    paymentCancelled: 'Your payment has been cancelled',
    statusUpdateFailed:
      'Your payment is successful but we could not update your status. Please try again later or contact support.',
    updatingSubscription: 'Updating your subscription details. \n This may take a moment.',
    notAvailable:
      'The subscriptions are currently unavailable. To ensure a smooth experience, please verify that you are using the most recent version of our app. If the issue persists, please attempt again later or reach out to our support team for further assistance.',
    updateToLatest: 'Please update to the latest version of the app to start your subscription.',
    restore: 'Restore subscription',
    restored: 'Your subscription has been restored.',
    restoreFailed:
      'Your subscription could not be restored. Please try again later or contact support.',
  },
};
