export default {
  activityLevelOnboarding: 'How active are you besides sports and working hours?',
  activityLevels: {
    light: 'Average active',
    moderate: 'Quite active',
    heavy: 'Very active',
  },
  choseYourSport: 'Choose your sport',
  dailyEnergyNeed: 'Energy needs',
  dateOfBirth: 'Date of birth',
  hours: 'Hours',
  workingHours: 'Working hours',
  length: 'Height',
  notAvailable: 'Not available yet',
  nutrients: {
    calories: 'Energy',
    carbs: 'Carbs',
    fat: 'Fats',
    fiber: 'Fiber',
    protein: 'Protein',
  },
  sports: {
    cycling: 'Cycling',
    fitness: 'Fitness',
    running: 'Running',
    teamsport: 'Team sport',
    hockey: 'Hockey',
    soccer: 'Football',
    swimming: 'Swimming',
    swimmingOpenWater: 'Swimming (open water)',
  },
  whichTeamSport: 'Which team sport do you participate in most often?',
  workTypes: {
    unemployed: 'I don’t work',
    sedentary: 'Physically passive (sitting down)',
    light: 'Mildly active',
    moderate: 'Quite active',
    heavy: 'Very active',
  },
  yourWeight: 'Weight',
};
