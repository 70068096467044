import BaseType from './baseType';

export class TipMenu extends BaseType {
  constructor({
    buttons = [],
    x = 0,
    y = 0,
    element = null,
    onClose = null,
    component = null,
    ...args
  }) {
    super(component, onClose);
    this.buttons = buttons;
    this.type = 'tipmenu';
    this.x = x;
    this.y = y;
    this.element = element;

    Object.keys(args).forEach(key => {
      this[key] = args[key];
    });
  }

  cancel() {
    super.close();
    if (this.onCancel) this.onCancel(this);
  }

  confirm() {
    if (this.onConfirm) this.onConfirm(this);
  }
}
