export default {
  cm: '{n} cm',
  highlightMoments: {
    preWorkoutMeal: '{n} pre-workout meals | {n} pre-workout meal | {n} pre-workout meals',
    preWorkoutSnack: '{n} pre-workout snacks | {n} pre-workout snack | {n} pre-workout snacks',
    recoveryMeal: '{n} recoverymeals | {n} recoverymeal | {n} recoverymeals',
    recoverySnack: '{n} shakes | {n} shake | {n} shakes',
  },
  watts: '{n} watt',
  hour: '{n}h',
  min: '{n}m',
  km: '{n} km',
  tempo: '{n} min/km',
  tempo100m: '{n} min/100m',
};
