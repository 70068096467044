<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<script>
  import { JumButton, JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import FcLinkGroup from '@/modules/shared/components/link-group/LinkGroup';
  import FcLinkGroupItem from '@/modules/shared/components/link-group-item/LinkGroupItem';
  import FcLinkGroupItemContent from '@/modules/shared/components/link-group-item-content/LinkGroupItemContent';
  import SEARCH_INGREDIENTS_QUERY from '@/modules/planner/graphql/queries/searchIngredients.query.graphql';
  import PRODUCT_HISTORY_QUERY from '@/modules/planner/graphql/queries/getProductHistory.query.graphql';
  import FcClearButton from '@/modules/shared/components/clear-button/ClearButton';
  import { userProfileMixin } from '@/modules/shared/mixins/userProfile.mixin';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { useRoute } from 'vue-router/composables';
  import { ItemType, UnitOfMeasure } from '@blancofoodcoach/content-scaling';
  import { product } from '@/modules/shared/constants/product.const';
  import { defineComponent } from 'vue';
  import { useMoment } from '@/modules/shared/composables/useMoment';

  export default defineComponent({
    name: 'SearchProducts',
    components: {
      DefaultSpacer,
      FcClearButton,
      FcLinkGroup,
      FcLinkGroupItem,
      FcLinkGroupItemContent,
      JumHeading,
      JumButton,
      JumIcon,
    },
    mixins: [userProfileMixin],
    props: {
      searchQuery: {
        type: String,
        default: '',
      },
    },
    emits: ['add-to-meal', 'selected'],
    setup(_props, { emit }) {
      const route = useRoute();
      const { moment } = useMoment();
      const { id } = route.params;
      const select = productId => {
        emit('selected', productId);
      };

      return {
        moment,
        recipeId: id,
        select,
      };
    },
    data: () => ({
      ingredients: [],
      UnitOfMeasure,
      ItemType,
    }),
    apollo: {
      history: {
        query: PRODUCT_HISTORY_QUERY,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            moment: this.moment,
          };
        },
        skip() {
          return !this.moment;
        },
        update: ({ getProductHistory: { ingredients, selectedUnitOfMeasures } }) => ({
          ingredients,
          selectedUnitOfMeasures,
        }),
      },
      ingredients: {
        query: SEARCH_INGREDIENTS_QUERY,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            query: this.searchQuery,
          };
        },
        skip() {
          return (
            !this.searchQuery ||
            (this?.searchQuery?.length < 3 &&
              !product.search.whitelist.includes(this.searchQuery.toLowerCase()))
          );
        },
        update: ({ searchIngredients: { ingredients } }) => ingredients,
      },
    },
    watch: {
      searchQuery: {
        handler() {
          this.$apollo.queries.ingredients.refetch();
        },
      },
    },
    activated() {
      this.$apollo.queries.ingredients.refetch();
    },
    methods: {
      displayName(ingredient) {
        const [brand] = ingredient.brand || [];
        if (ingredient.market === 'OFF' && brand && brand.trim() !== '') {
          const [firstBrand] = ingredient.brand;
          return `${ingredient.name} (${firstBrand})`;
        }
        return ingredient.name;
      },
      addToMeal(ingredient) {
        const factor =
          this.history.selectedUnitOfMeasures.find(uom => uom.ingredientId === ingredient.id)
            ?.factor ?? 1;
        this.$emit('add-to-meal', ingredient, factor);
      },
      addOwnIngredient() {
        this.$modaly({
          title: this.$t('planner.modals.addOwnIngredient.title'),
          message: this.$t('planner.modals.addOwnIngredient.message'),
          onConfirm: modal => modal.close(),
          confirmText: this.$t('planner.modals.addOwnIngredient.confirmText'),
        });
      },
    },
  });
</script>

<template>
  <fc-page-content>
    <fc-page-content-breakout>
      <fc-link-group
        v-if="!searchQuery && history && history.ingredients && history.ingredients.length"
      >
        <fc-link-group-item :chevron="false">
          <jum-heading h5>
            <strong>{{ $t('planner.headings.recentlyUsedProducts') }}</strong>
          </jum-heading>
        </fc-link-group-item>
        <fc-content-spinner v-if="$apollo.queries.history.loading" />
        <template v-else>
          <fc-link-group-item
            v-for="ingredient in history.ingredients"
            :key="`ingredient-${ingredient.id}`"
            :chevron="false"
            :data-test-id="`ingredient-${ingredient.id}`"
          >
            <fc-link-group-item-content class="ingredient-list-item">
              <fc-clear-button
                :data-test-id="`ingredient-info-${ingredient.id}`"
                class="ingredient-info"
                @click="select(ingredient.id)"
              >
                {{ ingredient.name }}<br />

                <span class="kcal-g">
                  {{ $tc('shared.counts.kcal', ingredient.macros.calories.toFixed(0)) }} /
                  {{ $tc(`shared.counts.${UnitOfMeasure.Grams}`, 100) }}
                  <span v-if="ingredient.__typename === ItemType.UserIngredient" class="addition">{{
                    $t('planner.labels.myIngredient')
                  }}</span>
                </span>
              </fc-clear-button>
              <jum-button
                circle
                secondary
                :data-test-id="`add-ingredient-${ingredient.id}`"
                @click="addToMeal(ingredient)"
              >
                <jum-icon icon="jum-plus" />
              </jum-button>
            </fc-link-group-item-content>
          </fc-link-group-item>
        </template>
      </fc-link-group>

      <fc-link-group v-if="searchQuery">
        <fc-link-group-item :chevron="false">
          <jum-heading h5>
            <strong>{{ $t('planner.titles.searchResults') }}</strong>
          </jum-heading>
        </fc-link-group-item>
        <fc-content-spinner v-if="$apollo.queries.ingredients.loading" />
        <template v-else>
          <fc-link-group-item
            v-for="ingredient in ingredients"
            :key="`ingredient-${ingredient.id}`"
            :chevron="false"
            :data-test-id="`ingredient-${ingredient.id}`"
          >
            <fc-link-group-item-content class="ingredient-list-item">
              <fc-clear-button
                :data-test-id="`ingredient-info-${ingredient.id}`"
                class="ingredient-info"
                @click="select(ingredient.id)"
              >
                {{ displayName(ingredient) }}<br />

                <span class="kcal-g">
                  {{ $tc('shared.counts.kcal', ingredient.macros.calories.toFixed(0)) }} /
                  {{ $tc(`shared.counts.${UnitOfMeasure.Grams}`, 100) }}
                  <span v-if="ingredient.__typename === ItemType.UserIngredient" class="addition">{{
                    $t('planner.labels.myIngredient')
                  }}</span>
                </span>
              </fc-clear-button>

              <jum-button
                circle
                secondary
                :data-test-id="`add-ingredient-${ingredient.id}`"
                @click="addToMeal(ingredient)"
              >
                <jum-icon icon="jum-plus" />
              </jum-button>
            </fc-link-group-item-content>
          </fc-link-group-item>
        </template>
      </fc-link-group>
    </fc-page-content-breakout>

    <div class="no-matching-ingredients">
      <template v-if="searchQuery && ingredients.length === 0">
        <default-spacer />
        <jum-heading h6 bold>{{ $t('planner.headings.noMatchingProducts') }}</jum-heading>
        <p>{{ $t('planner.info.noMatchingProducts') }}</p>
      </template>
      <template v-else>
        <jum-heading h6 bold>{{ $t('planner.headings.addOwnProduct') }}</jum-heading>
      </template>

      <RouterLink
        v-slot="{ navigate }"
        :to="{ name: 'planner-meals-create-ingredient', query: { recipeId } }"
        custom
      >
        <jum-button secondary @click="navigate">
          {{
            searchQuery
              ? $t('planner.buttons.addMissingProduct')
              : $t('planner.buttons.addOwnProduct')
          }}
        </jum-button>
      </RouterLink>
    </div>
  </fc-page-content>
</template>

<style lang="scss" scoped>
  @import '~@/styles/global.scss';

  .ingredient-list__toggle {
    display: flex;
    justify-content: center;

    fc-toggle-label {
      margin: 0;
    }

    .fc-label:first-of-type {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
      margin-right: 0;
    }

    .fc-label:last-of-type {
      border-bottom-left-radius: 0;
      border-left: 0;
      border-top-left-radius: 0;
      margin-left: 0;
    }
  }

  .no-matching-ingredients {
    padding: 32px 0;
    text-align: center;
  }

  .ingredient-list-item {
    justify-content: space-between;
    padding-bottom: $spacing-s;
    padding-top: $spacing-s;
  }

  .ingredient-info {
    flex: 1;
    margin-right: $spacing-m;
    text-align: left;
  }

  .kcal-g {
    color: $color-gray-lighten-30;
    display: block;
    font-size: $font-size-tiny;
    line-height: $line-height-tiny;
  }

  .addition::before {
    content: '\2022';
    margin: 0 3px;
  }
</style>
