import { TRAINING_TYPES, trainingTypesTranslations } from './trainingTypes.nl';

const FOOD_DURING_ACTIVITY = 'Eten tijdens je activiteit';

export default {
  activityLevel: 'Activiteitenniveau',
  activityLevelOnboarding: 'Hoe actief ben je buiten je sport en werk om?',
  activityLevels: {
    light: 'Gemiddeld actief',
    moderate: 'Erg actief',
    heavy: 'Extreem actief',
  },
  copyMeal: 'Kopieer maaltijd',
  chooseRecipeMoments: 'Waar wil je dit recept nog meer tonen?',
  chooseFrom: 'Kies uit',
  chooseRecipeName: 'Kies een naam voor je {n}',
  chooseYour: 'Kies je',
  choseYourSport: 'Kies je teamsport',
  date: 'Datum',
  dateOfBirth: 'Geboortedatum',
  energyRequirement: 'Macronutriënten',
  dayOfWeek: {
    Mo: 'Ma',
    Tu: 'Di',
    We: 'Wo',
    Th: 'Do',
    Fr: 'Vr',
    Sa: 'Za',
    Su: 'Zo',
  },
  matchingNutrients: {
    title: 'Dit is perfect passend! Controleer macronutriënten',
    subtext:
      'Deze maaltijd is afgestemd op uw energiebehoeften. Je bent op weg om aan je dagelijkse behoeften te voldoen.',
  },
  mealMoment: 'Maaltijdmoment',
  moveMeal: 'Verplaats maaltijd',
  notMatchingNutrients: {
    title: 'Geweldige keuze! Let op dat niet alle macronutriënten binnen uw bereik vallen',
    subtext:
      "Deze maaltijd is afgestemd op je energiebehoeften. Sommige macro's voor dit moment zijn niet 100% perfect. U kunt zich richten op deze macro's in de volgende maaltijden om uw dagelijkse doel te bereiken.",
  },
  doesMatchMealPlan: 'Past perfect',
  myRecipe: 'Mijn recept',
  myIngredient: 'Mijn product',
  doesNotMatchMealPlan: 'Voldoet niet aan voedingsplan',
  ftpValue: 'Je geschatte FTP-waarde',
  highlightMoments: {
    preWorkoutMeal: 'Pre-workout maaltijd',
    preWorkoutSnack: 'Pre-workout snack',
    recoveryMeal: 'Herstelmaaltijd',
    recoverySnack: 'Shake',
  },
  showPreparedAmounts: 'Toon bereide hoeveelheden',
  hockey: {
    matchDayKeeper: 'Wedstrijd (keeper)',
    matchDayPlayer: 'Wedstrijd (veldspeler)',
    normalTraining: 'Normale training',
    intensiveTraining: 'Intensieve training',
    keeperTraining: 'Keeperstraining',
  },
  fitness: {
    strength: TRAINING_TYPES.STRENGTH_TRAINING,
    cardioGroup: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
    cardioStrength: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
    circuitCrossfit: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
    cardioStrengthEasy: 'Rustig',
    cardioStrengthModerate: 'Gemiddeld',
    cardioStrengthIntensive: 'Intensief',
    strengthLotsResistance: 'Weinig weerstand',
    strengthLittleResistance: 'Veel weerstand',
    littleRest: '0-120 sec',
    lotsRest: '> 120 sec',
    cardioGroupLessonEasy: 'Rustig',
    cardioGroupLessonModerate: 'Gemiddeld',
    cardioGroupLessonIntensive: 'Intensief',
    circuitCrossfitModerate: 'Gemiddeld',
    circuitCrossfitIntensive: 'Intensief',
  },
  hockeyDuration: 'Hoelang ga je trainen?',
  hockeyMoment: 'Ik ga trainen vóór mijn',
  hours: 'Uren',
  intensity: 'Je trainingsintensiteit',
  intensityType: 'Intensiteit',
  restType: 'Rust tussen de sets',
  length: 'Lengte',
  mealDifficulties: {
    simple: 'eenvoudig',
    medium: 'gevorderd',
    hard: 'moeilijk',
  },
  moments: {
    afternoonSnack: 'Middagsnack',
    beforeAfternoonSnack: FOOD_DURING_ACTIVITY,
    beforeBreakfast: FOOD_DURING_ACTIVITY,
    beforeDinner: FOOD_DURING_ACTIVITY,
    beforeEveningSnack: FOOD_DURING_ACTIVITY,
    beforeHalfTime: FOOD_DURING_ACTIVITY,
    beforeLunch: FOOD_DURING_ACTIVITY,
    beforeMorningSnack: FOOD_DURING_ACTIVITY,
    beforeRecoverySnack: FOOD_DURING_ACTIVITY,
    beforeShake: FOOD_DURING_ACTIVITY,
    breakfast: 'Ontbijt',
    cherryJuice: 'Cherry juice',
    dinner: 'Diner',
    eveningSnack: 'Avondsnack',
    gel: 'Gel',
    halfTime: 'Rust',
    lunch: 'Lunch',
    morningSnack: 'Ochtendsnack',
    postGameMeal: 'Post-game meal',
    preGame: 'Pre-game',
    preGameMeal: 'Pre-game meal',
    preRaceMeal: 'Pre-race meal',
    preRaceSnack: 'Pre-race snack',
    preWarmingUp: 'Pre-warming-up',
    recoveryMeal1: 'Herstelmaaltijd 1',
    recoveryMeal2: 'Herstelmaaltijd 2',
    recoveryMeal: 'Herstelmaaltijd',
    recoverySnack: 'Shake',
    shake: 'Shake',
    snack: 'Snack',
  },
  minimumIntake: 'Minimale energiebehoefte:',
  notAvailable: 'Nog niet beschikbaar',
  nutrientPer100OfUnit: '{nutrient} per 100 {unit}',
  nutrients: {
    calories: 'Energie',
    carbs: 'Koolhydraten',
    fat: 'Vetten',
    fiber: 'Vezels',
    protein: 'Eiwitten',
  },
  nutritionalValues: 'Voedingswaarden',
  products: 'Producten',
  recipes: 'Recepten',
  tunedRecipes: 'Tuned recepten',
  running: {
    distance: 'Afstand',
    tempo: 'Tempo (min/km)',
  },
  recipeTypes: {
    breakfast: 'Ontbijt',
    lunch: 'Lunch',
    dinner: 'Diner',
    snack: 'Snack',
  },
  searchRecipes: 'Wat wil je eten?',
  sleepingHours: 'Slaapuren',
  soccerDuration: 'Hoelang ga je trainen?',
  sports: {
    cycling: 'Wielrennen',
    fitness: 'Fitness',
    running: 'Hardlopen',
    teamsport: 'Teamsport',
    hockey: 'Hockey',
    soccer: 'Voetbal',
  },
  tileFilters: {
    all: 'Bekijk alle',
    vegetarian: 'Vegetarisch',
  },
  today: 'Vandaag',
  trainingDuration: 'Hoelang ga je trainen?',
  actualDuration: 'Hoelang heb je getraind?',
  trainingMoment: 'Ik ga sporten vóór mijn',
  trainingMoments: {
    morning: 'Ochtend',
    afternoon: 'Middag',
    evening: 'Avond',
    beforeBreakfast: 'Ontbijt',
    beforeMorningSnack: 'Ochtendsnack',
    beforeLunch: 'Lunch',
    beforeAfternoonSnack: 'Middagsnack',
    beforeRecoverySnack: 'Shake',
    beforeDinner: 'Diner',
    beforeEveningSnack: 'Avondsnack',
  },
  trainingTypes: {
    ...trainingTypesTranslations(),
  },
  trainingType: 'Type training',
  trainingMainType: 'Type training',
  intensityTypes: {
    strengthLotsResistanceLittleRest: 'Weinig weerstand',
    strengthLotsResistanceLotsRest: 'Weinig weerstand',
    strengthLittleResistanceLittleRest: 'Veel weerstand',
    strengthLittleResistanceLotsRest: 'Veel weerstand',
    cardioStrengthEasy: 'Rustig',
    cardioStrengthModerate: 'Gemiddeld',
    cardioStrengthIntensive: 'Intensief',
    cardioGroupLessonEasy: 'Rustig',
    cardioGroupLessonModerate: 'Gemiddeld',
    cardioGroupLessonIntensive: 'Intensief',
    circuitCrossfitModerate: 'Gemiddeld',
    circuitCrossfitIntensive: 'Intensief',
  },
  units: {
    gram: 'Gram',
    milliliter: 'Milliliter',
  },
  unitOfMeasure: 'Eenheid',
  weight: 'Gewicht',
  workingHours: 'Werkuren',
  workType: 'Type werk',
  whichTeamSport: 'Welke teamsport doe je?',
  workTypes: {
    unemployed: 'Geen werk',
    sedentary: 'Zittend werk',
    light: 'Licht actief werk',
    moderate: 'Gemiddeld actief werk',
    heavy: 'Zwaar actief werk',
  },
  yourWeight: 'Je gewicht',
  ingredientName: 'Geef een naam aan je product',
  ingredientNameAlreadyExists: 'Dit product bestaat al, kies een andere naam',
};
