import { between, required } from 'vee-validate/dist/rules.umd';
import timeToSeconds from '@/modules/shared/filters/time-to-seconds.filter';

// Ftp form rules
const ftpRules = [
  {
    name: 'duration_required',
    message: 'core.inputErrors.required.duration',
    rule: required,
  },
  {
    name: 'duration_invalid',
    message: 'core.inputErrors.required.duration',
    rule: between,
  },

  // wattage
  {
    name: 'wattage_required',
    message: 'core.inputErrors.required.wattage',
    rule: required,
  },
  {
    name: 'wattage_invalid',
    message: 'core.inputErrors.invalid.wattage',
    rule: between,
  },

  // carbs per hour
  {
    name: 'carbsPerHour_invalid',
    message: 'core.inputErrors.invalid.carbsPerHour',
    rule: between,
  },

  // training moment
  {
    name: 'training_moment_required',
    message: 'core.inputErrors.required.trainingMoment',
    rule: required,
  },

  // type
  {
    name: 'type_required',
    message: 'core.inputErrors.required.trainingType',
    rule: required,
  },

  // date
  {
    name: 'date_required',
    message: 'core.inputErrors.required.default',
    rule: required,
  },

  // time
  {
    name: 'time',
    message: 'core.inputErrors.invalid.tempo',
    rule: {
      validate: time => !!time && /^([0-1]?\d|2[0-3]):[0-5]\d$/.test(time),
    },
  },
  {
    name: 'tempo_range',
    message: 'core.inputErrors.invalid.tempo',
    rule: {
      validate: time => {
        try {
          const seconds = timeToSeconds(time);
          return seconds >= 150 && seconds <= 900;
        } catch {
          return false;
        }
      },
    },
  },
  {
    name: 'tempo100m_range',
    message: 'core.inputErrors.invalid.tempo100m',
    rule: {
      validate: time => {
        try {
          const seconds = timeToSeconds(time);
          return seconds >= 49 && seconds <= 239;
        } catch {
          return false;
        }
      },
    },
  },
];

export default ftpRules;
