export default {
  cm: '{n} cm',
  highlightMoments: {
    preWorkoutMeal:
      '{n} pre-workout maaltijden | {n} pre-workout maaltijd | {n} pre-workout maaltijden',
    preWorkoutSnack: '{n} pre-workout snacks | {n} pre-workout snack | {n} pre-workout snacks',
    recoveryMeal: '{n} herstelmaaltijden | {n} herstelmaaltijd | {n} herstelmaaltijden',
    recoverySnack: '{n} shakes | {n} shake | {n} shakes',
  },
  watts: '{n} watt',
  hour: '{n} uur',
  min: '{n} min',
  km: '{n} km',
  tempo: '{n} min/km',
  tempo100m: '{n} min/100m',
};
